import React, {useEffect, useRef, useState} from 'react';
// import autocomplete from '/temp_js/autocomplete.js';
import JcAutocomplete from '/assets/modules/autocomplete';
import {useLazyQuery} from "@apollo/client";
import Spinner from "react-bootstrap/Spinner";

const SuperSelectInput = React.forwardRef((props, ref) => {
    const {as, textareaclassname,className, ...newProps} = props;
    
    if(as === 'textarea'){
        let newClassName = className + ' ' + textareaclassname;
        return (
            <textarea ref={ref} className={newClassName} {...newProps}  ></textarea>
        )
    }else{
        return (
            <input type="text" ref={ref} className={className} {...newProps} />
    )
    }
})

const MpSuperSelect = ({
                           query,
                           tableName,
                           field,
                           handleSelectItem,
                           handleChangeText = () => {},
                           defaultValue,
                           disabled,
                           varConst,
                           inputRef,
                           className,
                           containerClassName,
                           placeholder,
                           as,
                            ...props
                       }) => {

    let synonyms = [];

    let arrayData = [];

    let queryVars = varConst;

    let requestOnBuild = true;

    if(props.requestOnBuild !== undefined){
        requestOnBuild = props.requestOnBuild;
    }

    if (queryVars === undefined) {
        queryVars = {};
    }

    let defaultClassName = 'global-input form-control jc_autocomplete form-control-hg ps-4'
    let correctionClassName = ' p-0 ';
    if(as !== 'textarea'){  
        correctionClassName = '';
    }

    let history_data = '';
    let items_category = [];
    let source_loading = false; // признак ajax загрузки
    let source_last = ''; // отслеживание последнего запрошенного пока грузится

    let defplaceholder ='Начните вводить название';
    if(placeholder){
        defplaceholder = placeholder;
    }

    const QUERY = query;
    const containerRef = useRef(null)
    const thisRef = useRef(null);

    let variables = {};

    if(props.public){
        variables = {public:true}
    }

    const [getQuery, {
        loading,
        error,
        data
    }] = useLazyQuery(QUERY, {variables});


    const responseList = useRef([]);

    //const inputRefCur = inputRef ?? useRef(null); // Можно ли так делать? но это единственный способ как передать ссыль, но работать если не передали
                                                    // Так делать нельзя. Хуки не должны инициироваться внутри условий.
                                                    // Т.е. при каждом рендере компонента порядок хуков не должен меняться



    //const inputRefCur = useRef(inputRef);           // Так тоже нельзя - ссылка inputRef теряется

    const inputRefTest = useRef(null);
    const inputRefCur = inputRef ?? inputRefTest;     // Так вроде ничего не мешает


    const filter_names = (term, modifyHtml) => {
        let modify_html = modifyHtml || false;

        let res = [];

        if (term.length !== 0) {
            if (synonyms.length > 0) {
                let arr1 = [], arr2 = [];
                for (let i in synonyms) {
                    let name = synonyms[i];
                    if (name) {
                        let pos = name.indexOf(term);
                        if (pos === 0) {
                            arr1[arr1.length] = name;
                            //arr1[arr1.length]=name+'pos:'+pos;
                        } else if (pos > 0) {
                            arr2[arr2.length] = name;
                            //arr2[arr2.length]=name+'pos:'+pos;
                        }
                    }
                }
                res = arr1.concat(arr2).slice(0, 30);
            }
        } else {
            res = [];
        }

        res = {
            'synonyms': synonyms, // вот тут synonyms написано это обязательное название??? почему синониым?? по идее это массив термов
            'history_data': history_data,
            'items_category': items_category,
            'arrayData': arrayData
        }

        return res;
    }

    const onSelect = (item) => {

        let list = responseList.current;
        // вытаскиваем item из списка, что пришел нам с бэка и уже в дальнейшем работаем с ним
        let newItem = null
        if(Object.prototype.toString.call(list) === '[object Array]') {
            newItem = list ? list.filter(listItem => listItem?.id === +item?.id)[0] : null;
        }
        // а если нет id, то забираем, и передаем то что прилетело
        handleSelectItem(newItem ?? item);
    }


    useEffect(() => {
        if(requestOnBuild){
            let queryVars = {};
            queryVars['filter'] = defaultValue.label ?? "";
            getQuery({variables: queryVars})
                .then((resp) => {
                    responseList.current = resp.data[tableName];
                    onSelect(defaultValue);
                })
        }
    }, []);


    useEffect(() => {

        if (inputRefCur?.current) {
            JcAutocomplete.create(inputRefCur, {
                minChars: 3,
                cache: 0,
                delay: 500,
                handleSelectItem: function (item) {

                    //console.log('SELECTED ITEM', item);
                    onSelect(item)
                },
                onSelect: function (event, term, html_item) {
                    //console.log(html_item)
                    let item = {
                        value: html_item.dataset.val,
                        id: html_item.dataset.id,
                    }
                    //console.log('SELECTED ITEM', item);
                    onSelect(item)
                },
                source: function (term, request, response) {

                    source_loading = true;
                    this._request(term, response);
                },
                _request: function (term, response) {
                    //console.log('TREM: ' + term);
                    queryVars['filter'] = term;
                    if (disabled) return;
                    getQuery({variables: queryVars})
                        .then(resp => {
                            // сохраняем результат запроса в первозданном виде себе чтобы что?
                            arrayData = resp.data[tableName];

                            // если item не array, то может это и есть само значение
                            if (!Array.isArray(arrayData)) {
                                arrayData = arrayData[field];
                            }

                            responseList.current = arrayData

                            synonyms = arrayData.map((item) => {
                                return item[field];
                            })

                            if (source_last !== '') {
                                // Если мы долго грузили, то используем последний запрошенный
                                response(filter_names(source_last, true));
                                source_last = ''
                            } else {
                                response(filter_names(term, true));
                            }
                        })
                },
                renderItem: function renderItem(item, search) {
                    if (item && search) {
                        search = search.replace(/[^\w\s]/g, '\\$&');
                        var re = new RegExp("(".concat(search.split(' ').join('|'), ")"), 'gi');

                        let itemTitle = item[field];

                        if (!itemTitle) {
                            itemTitle = item;
                        }

                        return "<div id=\"" + item.id + "\" class=\"".concat(this.itemClass, "\" data-id=\"").concat(item.id, "\"").concat("data-val=\"").concat(itemTitle.replace(/"/gi, '&quot;').replace(/<b>/g, '').replace(/<\/b>/g, ''), "\">").concat(itemTitle.replace(re, '$1'), "</div>");
                    }
                },

                renderBlank: function renderItem(value) {
                    return "<div id=\"0\" class=\"autocomplete-item-not-found\">Не найдено</div>";
                },

                renderHistory: function renderHistory(item, search) {
                    search = search?.replace(/[^\w\s]/g, '\\$&');
                    var re = new RegExp("(".concat(search.split(' ').join('|'), ")"), 'gi');
                    return "<div class=\"".concat(this.itemClass, ' history', "\" data-val=\"").concat(item.replace(/"/gi, '&quot;').replace(/<b>/g, '').replace(/<\/b>/g, ''), "\">").concat(item.replace(re, '$1'), "</div>");
                },
                renderCategory: function renderCategory(item, search) {
                    search = search?.replace(/[^\w\s]/g, '\\$&');

                    let cat = `<span class="text-muted"><span class="ms-2 me-2">/</span> ${item.category}</span>`;

                    var re = new RegExp("(".concat(search.split(' ').join('|'), ")"), 'gi');
                    return "<div class=\"".concat(this.itemClass, ' category', "\" data-val=\"").concat(item.value.replace(/"/gi, '&quot;').replace(/<b>/g, '').replace(/<\/b>/g, ''), "\">").concat(item.value.replace(re, '$1'), cat, "</div>");
                },
                createContainer: function createContainer(input = null, containerClass = null) {
                    // console.log('aaaaaaaaa')
                    // console.log(input)
                    // const container = containerCreate(containerClass)

                    // console.log('inputinputinputinputinputinput');


                    // let el = input ?? this.input;

                    // console.log(this.input);

                    // return ;
                    // inputAddContainer(container)

                    // if (input && input.current) {
                    //     input.current.appendChild(container);
                    // }

                    return containerRef;
                },
            });
        }

        return (() => {
            if (inputRefCur?.current) {
                inputRefCur.current.destroy();
            }
        })
    }, [])


    //console.log('Обновление компоненты mp.superSelect defaultValue(city_name):', defaultValue);


    const onChange = (text) => {
        handleChangeText(text)
    }

    const onClick = (e) => {
        if(inputRefCur.current){
            // temp_comment
            inputRefCur.current.focus();// = true;
        }
    }

    const onKeyDown = (e) => {
        //console.log('key down', e.keyCode);
        if(e.keyCode === 9){

            // проблема в том, что если фокус попал в инпут, который находится внутри дива
            // то фокус по табу не выйдет из дива
            // для этого обработчик ожидает нажатия кнопки табуляции
            if(inputRefCur.current){
                // поиск всех видимых инпутов, текстареа и селектов
                let foundIndex = -1;
                let arr = $('input[type=text]:visible,textarea:visible,select:visible');
                // найти себя в этом списке
                arr.each( (idx, el) => {
                    if(inputRefCur.current === el ){
                        foundIndex = idx;
                        return false;
                    }
                })
                // перейти на элемент следующий по списку
                if((foundIndex > -1) && ((foundIndex+1) < arr.length)){
                    arr[foundIndex+1].focus();
                }
            }
        }
    }

    const onBlur = (e) => {
        if(props.onBlur){
            props.onBlur(e);
        }
    }

    let styleTotal = { background: "transparent", border: "0", outline:"none"};
    let newStyle = {};
    for(let key in styleTotal){
        newStyle[key] = styleTotal[key];
    }

    for(let key in props.style){
        newStyle[key] = props.style[key];
    }

    return (

        <div className="" id="inputSearch">
            <div className={`m-0 `} >

                <div id="form_ssel_search" ref={thisRef}
                     className=  {"d-flex position-relative form_search " + (className ?? defaultClassName) + correctionClassName}
                     onClick={onClick}
                     style={{cursor:"text"}}
                >

                        <SuperSelectInput
                            onKeyDown={onKeyDown}
                            className="w-100"
                            placeholder={defplaceholder}
                            aria-label={defplaceholder}
                            defaultValue={defaultValue.label}
                            ref={inputRefCur}
                            disabled={disabled}
                            onChange={onChange}
                            onBlur={onBlur}
                            style={newStyle}
                            as={as}
                            textareaclassname={props.textareaclassname}
                        />
                        {loading &&
                            <Spinner animation="border" className="position-absolute inset-y-0 end-0 my-auto d-block me-2 " size="sm" variant="dark"/>
                        }
                </div>

            </div>
            <div className='autocomplete-dropdown d-none' ref={containerRef}/>
        </div>
    );
};

export default MpSuperSelect;

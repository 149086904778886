import React, {useEffect, useRef, useState} from 'react';
import {gql, useLazyQuery} from "@apollo/client";
import MpSuperSelect from "../components/mp.superSelect";


const QUERY_CITIES = gql`
        query Cities($filter: String){
            getCities(
                page:1,
                onpage:5,
                sort:[{
                    field:"id",
                    direction:"asc",
                    order:0
                    },],
                filters:{name: $filter}
            ){id,name,delivery_options, point_count}
        }`

export const MpCitySelect = (props) => {

    // const {changedSelect} = props
    const changedSelect = (params) => {
        //console.log(params)
        props.setStorageCity(params)
    }

    let storageCity = props.storageCity ?? null

    if(!storageCity){
        console.log('CITY::NONE');
        // показывать запрос города
        // по умолчанию Москва
        storageCity = {id:605,caption:"Москва",source:'default'};
        // возможность поиска по местоположению
    }

    const [selectedCity, setSelectedCity] = useState();


    let defaultOption = {label: storageCity.caption, id: storageCity.id};

    /*Настройка Select под стили*/

    // фильтр css свойств переданных в excluded из provided.
    const rsFilter = (provided,excluded,verbose) => {

        // перебор существующих стилей
        let new_provided = {};

        for (const prop in provided){

            if(!excluded.includes(prop)){
                new_provided[prop] = provided[prop];
                if(verbose){
                    console.log('passed: ' + prop);
                }
            }else{
                if(verbose){
                    console.log('excluded ' + prop);
                }
            }
        }
        return new_provided;
    }

    // исключение свойств для дива с классом rs_control
    const rsFilterControl = (provided) => {
        // стили по умолчанию, которые перекрывают стили из rs__control
        let excludeStyles = ['backgroundColor','borderColor','boxShadow'];
        return rsFilter(provided,excludeStyles);
    }


    // исключение свойств для дива с классом rs_valueContainer
    const rsFilterValueContainer = (provided) => {
        let excludeStyles = ['padding'];
        return rsFilter(provided,excludeStyles);
        //return provided;
    }

    // исключение свойств для дива с классом rs_inputContainer
    const rsFilterInputContainer = (provided) => {
        let excludeStyles = ['padding','paddingTop','paddingBottom','margin'];
        return rsFilter(provided,excludeStyles);
    }

    // исключение свойств для дива с классом rs_placeholder
    const rsFilterPlaceholder = (provided) => {
        let excludeStyles = ['color'];
        return rsFilter(provided,excludeStyles);
    }

    // исключение свойств для дива с классом rs_menu
    const rsFilterMenu = (provided, name) => {
        let excludeStyles = [];
        return rsFilter(provided,excludeStyles);
    }

    // исключение свойств для дива с классом rs_option
    const rsFilterOption = (provided, state) => {
        let excludeStyles = [];
        if((state?.isFocused) || (state?.isSelected)){
            excludeStyles.push('backgroundColor');
        }
        return rsFilter(provided,excludeStyles);
    }

    // настройка стилей для Select с городами
    const customStyles = {
        //clearIndicator: () => ({}),
        control: (provided) => rsFilterControl(provided),
        valueContainer: (provided) => rsFilterValueContainer(provided),
        input: (provided) => rsFilterInputContainer(provided),
        placeholder: (provided) => rsFilterPlaceholder(provided),
        menu: (provided) => rsFilterMenu(provided,'menu'),
        menuList: (provided) => rsFilterMenu(provided,'menuList'),
        option: (provided,state) => rsFilterOption(provided, state),
    }


    // обработка выбора города
    const onChange = (item) => {
        let inputValue = {...item, source:'user-from-modal'};

        setSelectedCity(inputValue)
    }

    // переменная для хранения тамаута для события onBlur 
    // используется для отмены таймацта в useEffect при смене города
    const [cityNameTimeout, setCityNameTimeout] = useState(null);
    
    useEffect(() => {
        if (selectedCity) {

            //console.log('cityNameTimeout', cityNameTimeout);
            //отменить таймаут до того как он сработает, если был выбран другой город
            if(cityNameTimeout) {
                clearTimeout(cityNameTimeout);
            }               
                
            changedSelect(selectedCity)
        }
    }, [selectedCity])

    //console.log('test city');
    // задержка onBlur для того что бы имя города не отображалось при выборе другого города
    // иначе при клике на попап с городами будет отображаться выбранного ранее города на короткое время
    const onBlur = (e) => {
        let tempTimeout = setTimeout(() => {
            if(e.target){
                e.target.value = selectedCity?.name
            }     
        }, 250);
        
        setCityNameTimeout(tempTimeout);
    }


    return (
        <MpSuperSelect
            query={QUERY_CITIES}
            onBlur={onBlur}
            tableName={'getCities'}
            field={'name'}
            handleSelectItem={onChange}
            placeholder={props.placeholder}
            defaultValue={defaultOption} //label: storageCity.caption, id: storageCity.id
            disabled={props.disabled}
            inputRef={props.cityRef}
            public={true}
        />
    )
}

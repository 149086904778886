(function (global, factory) {
    typeof exports === 'object' && typeof module !== 'undefined' ? module.exports = factory() :
        typeof define === 'function' && define.amd ? define(factory) :
            (global = typeof globalThis !== 'undefined' ? globalThis : global || self, global.JcAutocomplete = factory());
}(this, (function () { 'use strict';

    function JcAutocomplete(input, options) {

        for (var fn in this) {
            if (fn.charAt(0) === '_' && typeof this[fn] === 'function') {
                this[fn] = this[fn].bind(this);
            }
        }

        let defaults = {
            minChars: 0,
            delay: 150,
            offsetLeft: 0,
            offsetTop: 1,
            changeValue: true,
            containerClass: 'autocomplete-dropdown',
            itemClass: 'autocomplete-item',
            isOpen: false,
            keyboard: false,
            createContainer: function createContainer(input, containerClass) {},
            handleSelectItem: function handleSelectItem(item) {},
            // eslint-disable-next-line no-unused-vars
            source: function source(term, request, response) {},
            renderItem: function renderItem(item, search) {
                search = search.replace(/[^\w\s]/g, '\\$&');
                var re = new RegExp("(".concat(search.split(' ').join('|'), ")"), 'gi');
                return "<div class=\"".concat(this.itemClass, "\" data-val=\"").concat(item.replace(/"/gi, '&quot;'), "\">").concat(item.replace(re, '<mark>$1</mark>'), "</div>");
            },
            renderHistory: function renderHistory(item, search) {},
            renderCategory: function renderCategory(item, search) {},
            // eslint-disable-next-line no-unused-vars
            onSelect: function onSelect(event, term, item) {},
            renderBlank: function renderBlank(search) {}
        };
        this.input = input;
        this.options = options = _extend(defaults, options);
        let container = this.container = options.createContainer(input, this.options.containerClass);

        input.autocompleteReserve = input.getAttribute('autocomplete');
        input.autocomplete = 'off';
        input.lastValue = '';

        let that = this;

        _on(window, 'resize', this._updateContainer);

        _on(input, 'blur', this._blurHandler);

        _on(input, 'keydown', this._keydownHandler);

        _on(input, 'keyup', this._keyupHandler);

        if (!options.minChars) {
            _on(input,
                /*'focus'*/
                'click', (event) => {
                    this._focusHandler(event)
                    this.isOpen = true;
                });
        }

        let indicatorContainer = document.querySelector('.indicatorContainer');


        // _on(indicatorContainer, 'click', (event) => {
        //
        //     if (!this.isOpen) {
        //         this.input.lastValue = '\n';
        //         // if (!that.isInputFocus) {
        //
        //         // }
        //         // console.log(that.input.hasFocus())
        //
        //         this._focusHandler(event)
        //         that.input.focus();
        //         showContainer(container)
        //         this.isOpen = true
        //     } else {
        //         hideContainer(container)
        //         this.isOpen = false
        //     }
        // })


        _live(".".concat(options.itemClass), 'mouseover', function (event, context) {
            var selected = context.querySelector(".".concat(options.itemClass, ".selected"));

            if (selected) {
                selected.classList.remove('selected');
            }

            this.classList.add('selected');
        }, this.container.current);

        _live(".".concat(options.itemClass), 'click', function (event, context) {
            var dataset = this.dataset;

            let value = dataset.val;
            let id = dataset.id;

            let item = {
                value: value,
                id: id,
            }

            if (options.changeValue === true) {
                input.value = value;
            }

            input.lastValue = '';
            options.onSelect(event, value, this);
            // context.hide();
            hideContainer(container)

            options.handleSelectItem(item)

            // let url = `/search?qtext=${value}`;
            // window.location.replace(url);
        }, this.container.current);
    }

    JcAutocomplete.prototype = {
        constructor: JcAutocomplete,
        container: null,
        _suggest: function _suggest(data) {



            var input = this.input,
                options = this.options;
            var value = input.value;

            let synonyms = data.synonyms;
            let items_category = data.items_category;
            let history_data = data.history_data
            let arrayData = data.arrayData

            // synonyms = ['qqqq', 'wwww'];

            var itemsString = '';

            // if (history_data) {
            //     itemsString += options.renderHistory(history_data, value);
            // }

            if (synonyms.length && value.length >= options.minChars) {
                for (var i = 0; i < synonyms.length; i++) {
                    itemsString += options.renderItem(arrayData[i], value);
                    // itemsString += options.renderItem(synonyms[i], value);
                }
            }

            // if (items_category.length) {
            //     for (var i = 0; i < items_category.length; i++) {
            //         itemsString += options.renderCategory(items_category[i], value);
            //     }
            // }




            // console.log('history_data')
            // console.log(itemsString)
            // items_category

            if (itemsString) {
                this.container.current.innerHTML = itemsString;
                this._updateContainer(0);
            } else {
                if (value.length > 10) {
                    value = value.slice(0, 12) + '...';
                }
                itemsString += options.renderBlank(value);
                this.container.current.innerHTML = itemsString;
                this._updateContainer(0);
                // hideContainer(this.container.current);

            }
        },
        // _request: function (customRequest) {
        //     console.log(url)
        // },
        _updateContainer: function _updateContainer(resize, next) {
            var input = this.input,
                options = this.options,
                container = this.container.current;
            var rect = input.getBoundingClientRect();




            // if (container.style.position !== 'static') {
            // container.style.left = "".concat(Math.round(rect.left + (window.pageXOffset || document.documentElement.scrollLeft) + options.offsetLeft), "px");
            // container.style.top = "".concat(Math.round(rect.bottom + (window.pageYOffset || document.documentElement.scrollTop) + options.offsetTop), "px");
            // }

            if(this.container.current)
            this.container.current.style.width = "".concat(Math.round(rect.right - rect.left) + 237, "px"); // outerWidth

            if (!resize) {
                // container.show();
                showContainer(container);

                if (!container.maxHeight) {
                    container.maxHeight = parseInt(getComputedStyle(container).maxHeight);
                }

                if (!container.suggestionHeight) {
                    var first = container.querySelector(".".concat(options.itemClass)); // when not passed a single item

                    if (first !== null) {
                        container.suggestionHeight = first.offsetHeight;
                    }
                }

                if (container.suggestionHeight) if (!next) {
                    container.scrollTop = 0;
                } else {
                    var scrollTop = container.scrollTop;
                    var selectedTop = next.getBoundingClientRect().top - container.getBoundingClientRect().top;

                    if (selectedTop + container.suggestionHeight - container.maxHeight > 0) {
                        container.scrollTop = selectedTop + container.suggestionHeight + scrollTop - container.maxHeight;
                    } else if (selectedTop < 0) {
                        container.scrollTop = selectedTop + scrollTop;
                    }
                }
            }
        },
        _focusHandler: function _focusHandler(event) {
            this.input.lastValue = '\n';

            this._keyupHandler(event);
        },
        _blurHandler: function _blurHandler() {
            var overSb = document.querySelector(".".concat(this.options.containerClass, ":hover"));
            var input = this.input;
            var container = this.container.current;

            if (!overSb) {
                input.lastValue = input.value;
                setTimeout(function () {
                    // container.hide();
                    hideContainer(container);
                    this.isOpen = false;
                }, 0);
            } else if (input !== document.activeElement) {
                setTimeout(function () {
                    input.focus();
                    this.isOpen = true;
                }, 0);
            }
        },
        _keydownHandler: function _keydownHandler(event) {
            //console.log("_keydownHandler", this, event);
            var button = event.code;
            var input = this.input,
                options = this.options,
                container = this.container.current;

            if ((button === 'ArrowDown' || button === 'ArrowUp') && container.innerHTML) {
                var next;
                var selected = container.querySelector(".".concat(options.itemClass, ".selected"));

                if (!selected) {
                    var items = container.querySelectorAll(".".concat(options.itemClass));

                    if (items.length > 0) {
                        next = button === 'ArrowDown' ? items[0] : items[items.length - 1]; // first : last

                        next.classList.add('selected');

                        if (options.changeValue === true) {
                            input.value = next.dataset.val;
                        }
                    }
                } else {
                    selected.classList.remove('selected');
                    var el = selected; // so that you can add items to the list for which you do not need to run (example: the title for the group)

                    while (el && !next) {
                        el = button === 'ArrowDown' ? el.nextSibling : el.previousSibling;

                        if (el && _matches(el, ".".concat(options.itemClass))) {
                            next = el;
                        }
                    }

                    if (next) {
                        next.classList.add('selected');

                        if (options.changeValue === true) {
                            input.value = next.dataset.val;
                        }
                    } else {
                        input.value = input.lastValue;
                        next = 0;
                    }
                }

                this._updateContainer(0, next);

                event.preventDefault();
            } else if (button === 'Escape') {
                input.value = input.lastValue;
                hideContainer(container)
            } else if (button === 'Enter' || button === 'NumpadEnter' || button === 'Tab') {

                console.log('-----------------------1', container);
                var _selected = container.querySelector(".".concat(options.itemClass, ".selected"));



                //console.log('-----------------------2', _selected);
                if (_selected) {
                    let style = window.getComputedStyle(_selected);
                    if (style.display === 'none') {

                    }else{
                        //console.log('-----------------------4', style);
                        this.options.onSelect(event, _selected.dataset.val, _selected);
                        setTimeout(function () {
                            hideContainer(container)
                        }, 0);
                    }
                }else{

                }
                event.preventDefault();
            }
        },
        _keyupHandler: function _keyupHandler(event) {
            var controlButtons = ['Tab', 'Enter', 'NumpadEnter', 'Escape', 'End', 'Home', 'ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown'];
            var button = event.code;

            if (!button || !controlButtons.includes(button)) {
                var input = this.input,
                    options = this.options,
                    container = this.container.current,
                    _request = this._request,
                    _suggest = this._suggest;
                var value = input.value;
                input.lastValue = value;


                if (value.length >= options.minChars) {
                    clearTimeout(input.timer);
                    input.timer = setTimeout(function () {
                        options.source(value, _request, _suggest);
                    }, options.delay);
                } else {
                    hideContainer(container)
                }
            }
        },
        destroy: function destroy() {
            var input = this.input;

            _off(window, 'resize', this._updateContainer);

            _off(input, 'blur', this._blurHandler);

            _off(input, 'focus', this._focusHandler);

            _off(input, 'keydown', this._keydownHandler);

            _off(input, 'keyup', this._keyupHandler);

            if (input.autocompleteReserve) {
                input.autocomplete = input.autocompleteReserve;
            } else {
                input.removeAttribute('autocomplete');
            }

            this.container.current.remove();
            this.input = input = null;
        }
    };

    function _live(selector, event, callback, context) {
        context.addEventListener(event, function (event) {
            var found = false;
            var element = event.target;

            while (element && !(found = _matches(element, selector))) {
                element = element.parentElement;

                if (element === context) {
                    break;
                }
            }

            if (found === true) {
                callback.call(element, event, context);
            }
        });
    }

    const hideContainer = function (container) {
        if (container && container.current) {
            container.current.classList.add('d-none');
        } else if (container && container.classList) {
            container.classList.add('d-none');
        }

    };

    const showContainer = function (container) {
        if (container && container.current) {
            container.current.classList.remove('d-none');
        } else if (container && container.classList) {
            container.classList.remove('d-none');
        }
    };

    function _extend(dst, src) {
        if (dst && src) {
            for (var key in src) {
                if (src.hasOwnProperty(key)) {
                    dst[key] = src[key];
                }
            }
        }

        return dst;
    }

    function _on(element, event, fn) {
        element.addEventListener(event, fn, {
            capture: false,
            passive: false
        });
    }

    function _off(element, event, fn) {
        element.removeEventListener(event, fn, {
            capture: false,
            passive: false
        });
    }
    // /**
    //  * Polyfill for Element.prototype.matches
    //  * @param element
    //  * @param selector
    //  * @private
    //  */


    const _matches = function (element, selector) {
        var matches = Element.prototype.matches || Element.prototype.matchesSelector || Element.prototype.webkitMatchesSelector || Element.prototype.mozMatchesSelector || Element.prototype.oMatchesSelector || Element.prototype.msMatchesSelector;
        return matches.call(element, selector);
    }
    // // /**
    //  * Create JcAutocomplete instance
    //  * @param {HTMLElement} input
    //  * @param {Object} [options]
    //  */


    JcAutocomplete.create = function (input, options) {
        return new JcAutocomplete(input.current, options);
    }; // Export

    return JcAutocomplete;

})));
